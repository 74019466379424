<template>
  <div>
    <form-component
      @onSubmit="onSubmit"
      :navigation-group="navigationGroup"
      :product-subcategories="productSubcategories"
      @fetchByLocale="fetchByLocale" />
  </div>
</template>

<script>
import FormComponent from '@/components/navigation/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, updateOrCreate } from '@/http/navigation';
import { all } from '@/http/product/subcategory';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      navigationGroup: {
        name: '',
        navigation_subgroups: [
          {
            name: '',
            navigation_elements: [
              {
                name: '',
              },
            ],
          },
        ],
      },
      productSubcategories: [],
    };
  },

  async mounted() {
    Promise.all([this.fetchProductSubcategories(), this.fetchNavigationGroup({ lang: 'pl' })]);
  },

  methods: {
    async fetchByLocale(lang) {
      await this.fetchNavigationGroup(lang);
    },
    async fetchProductSubcategories() {
      try {
        const { data } = await all();
        this.productSubcategories = data.data;
      } catch (e) {
        this.showErrorNotification('Problem z pobraniem danych', 'Nie udało się pobrać danych podkategorii.');
      }
    },
    async fetchNavigationGroup(lang) {
      try {
        const { data } = await show(this.$route.params.id, lang);

        this.navigationGroup = data.data;
      } catch (error) {
        this.showErrorNotification('Problem z pobraniem danych', 'Nie udało się pobrać danych elementu nawigacji.');
      }
    },
    async onSubmit(form) {
      try {
        await updateOrCreate(form);

        this.showSuccessNotification('Dane zapisane', 'Element nawigacji został zaktualizowany.');
        this.$router.push({ name: 'navigation-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania elementu nawigacji. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
